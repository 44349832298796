import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { SsrProps } from '@/core/features/app/ssr-props';

import React from 'react';

import { ErrorPage } from '@/core/features';
import useDynamicPagePartialComponentQuery from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-partial-component-query';
import {
    useDynamicPageQueryData,
    useDynamicPageQueryFetching,
    useHydrateDynamicPageQueryData,
} from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';
import useDynamicPageQueryCacheHandler from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query-cache-handler';
import {
    isDynamicPageErrorResponse,
    mergeDynamicPageComponentsWithPartialComponents,
} from '@/core/features/a-dynamic-page/services/dynamic-page-service';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { useUnhandledInternalAppNativeLinkListener } from '@/core/features/link/app-native-link-forward-service';
import { logger } from '@/core/features/logger/logger';
import { useHydrateOverlayHistoryState } from '@/core/features/modals/overlay-history/overlay-history-state';
import Main from '@/core/features/page/main';
import { isAxiosError } from '@/core/features/request/axios';
import useRouterInit from '@/core/features/router/router-init';
import usePageScrollRestoration from '@/core/features/scroll/page-scroll-restoration/use-page-scroll-restoration';
import TrackingContainer from '@/core/features/tracking/tracking-container';
import DynamicPage from '@/features/a-dynamic-page/components/dynamic-page';
import PageDataComponentsSwitch from '@/features/a-dynamic-page/components/page-data-components-switch';

export default function DynamicPageContainer(ssrProps: SsrProps) {
    useHydrateDynamicPageQueryData();
    const { data, error } = useDynamicPageQueryData();
    const { isFetching, isPending } = useDynamicPageQueryFetching();

    if (!data?.response) {
        throw 'No Response found in Dynamic Page Container';
    }

    const { isLoading: isPartialQueryLoading, pageComponents: updatedPartialComponents } =
        useDynamicPagePartialComponentQuery(data?.response?.components ?? []);

    const [components, setComponents] = React.useState<PageComponent[]>(data?.response?.components ?? []);

    const [errorStatusCode, setErrorStatusCode] = React.useState<null | number>(
        isDynamicPageErrorResponse(data?.response) ? (data.response.code ?? null) : null,
    );

    const { isApp } = useDeviceoutput();
    useRouterInit({ network: { isLoading: isPending } });
    usePageScrollRestoration();

    useHydrateOverlayHistoryState();
    useUnhandledInternalAppNativeLinkListener({ isApp });

    // currently, this has to be here so that the invalidation is only done by one observer
    useDynamicPageQueryCacheHandler({
        cacheControl: data?.response?.meta.cacheControl ?? null,
        identifier: data?.response?.['@id'] ?? null,
        isCachedResponse: data?.cached ?? false,
        isDynamicPageCacheDisabled: ssrProps.isDynamicPageCacheDisabled,
    });

    React.useEffect(() => {
        if (isFetching) {
            return;
        }

        if (isAxiosError(error)) {
            setErrorStatusCode(error.response?.status ?? null);
            return;
        }

        if (isDynamicPageErrorResponse(data?.response)) {
            setErrorStatusCode(data?.response?.code);
            return;
        }

        if (error) {
            logger.error(`Error in dynamic-page-container: ${error} - ${JSON.stringify(error)}`);
            return;
        }

        if (!data?.response) {
            return;
        }

        const updatedComponents = mergeDynamicPageComponentsWithPartialComponents(
            data?.response.components,
            /**
             * prefer dynamic page response for fresh data if partial query takes too long
             * e.g.: https://c24-indi.atlassian.net/browse/INDI-7056?focusedCommentId=29493 - Point 2 (Headline Component)
             */
            isPartialQueryLoading ? data?.response.components : updatedPartialComponents,
        );
        setComponents(updatedComponents);
        setErrorStatusCode(null);
    }, [error, isFetching, isPartialQueryLoading, data?.response, updatedPartialComponents]);

    if (errorStatusCode && errorStatusCode !== 301 && errorStatusCode !== 302) {
        return (
            <Main>
                <ErrorPage
                    detail={'API'}
                    isPageLoading={false}
                    statusCode={errorStatusCode}
                />
            </Main>
        );
    }

    return (
        <>
            <PageDataComponentsSwitch components={components} />
            <DynamicPage
                components={components}
                isPageLoading={isFetching}
                meta={data?.response?.meta}
                ssrProps={ssrProps}
            />
            <TrackingContainer
                metaData={data?.response?.meta ?? null}
                url={ssrProps.ssrUrl}
            />
        </>
    );
}
