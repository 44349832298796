import React from 'react';

import { useRouterEvents } from '@/core/features/router/router-events';

const MAX_RENDER_COUNT = 5;

export default function useHasInteractedRef(contentElementRef: React.MutableRefObject<HTMLElement | Window | null>) {
    const hasInteractedRef = React.useRef(false);
    const routerEvents = useRouterEvents();
    const [renderCount, setRenderCount] = React.useState(0);

    React.useEffect(() => {
        // contentElementRef.current is initially null due to the createPortal taking 2+ renders to set the ref.
        // The number of render varies from browser to browser.
        if (!contentElementRef.current && renderCount < MAX_RENDER_COUNT) {
            setRenderCount((prev) => prev + 1);
            return;
        }

        const onRouteChangeStart = () => {
            hasInteractedRef.current = false;
            registerEventListeners();
        };
        const touchHandler = () => {
            hasInteractedRef.current = true;
            removeEventListeners();
        };

        const registerEventListeners = () => {
            contentElementRef.current?.addEventListener('click', touchHandler);
            contentElementRef.current?.addEventListener('touchstart', touchHandler);
            contentElementRef.current?.addEventListener('wheel', touchHandler);
            contentElementRef.current?.addEventListener('scroll', touchHandler);
        };
        const removeEventListeners = () => {
            contentElementRef.current?.removeEventListener('click', touchHandler);
            contentElementRef.current?.removeEventListener('touchstart', touchHandler);
            contentElementRef.current?.removeEventListener('wheel', touchHandler);
            contentElementRef.current?.removeEventListener('scroll', touchHandler);
        };

        registerEventListeners();
        routerEvents.on('routeChangeStart', onRouteChangeStart);

        return () => {
            removeEventListeners();
            routerEvents.off('routeChangeStart', onRouteChangeStart);
        };
    }, [contentElementRef, routerEvents, renderCount]);

    return hasInteractedRef;
}
