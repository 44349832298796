import { NEXT_PUBLIC_COOKIE_DOMAIN, NEXT_PUBLIC_SSO_PRODUCT_ID } from '@/constants/env';
import { logger } from '@/core/features/logger/logger';
import useWireframeLoaded from '@/core/features/wireframe/use-wireframe-loaded';

export default function useXPPSETTracking({ isDesktopView }: { isDesktopView: boolean }) {
    useWireframeLoaded(() => {
        if (!NEXT_PUBLIC_SSO_PRODUCT_ID || !NEXT_PUBLIC_COOKIE_DOMAIN) {
            logger.error('setXPPSET: NEXT_PUBLIC_SSO_PRODUCT_ID is not defined');
            return;
        }

        if (isDesktopView) {
            if (!window.Check24) {
                return;
            }
            // eslint-disable-next-line fp/no-mutation
            window.Check24.xppset.domain = NEXT_PUBLIC_COOKIE_DOMAIN;
            window.Check24.xppset.addProduct(NEXT_PUBLIC_SSO_PRODUCT_ID);
        } else {
            if (!window.C24M) {
                return;
            }
            // eslint-disable-next-line fp/no-mutation
            window.C24M.xppset.domain = NEXT_PUBLIC_COOKIE_DOMAIN;
            window.C24M.xppset.addProduct(NEXT_PUBLIC_SSO_PRODUCT_ID);
        }
    });
}
